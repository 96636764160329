.StapperMainContainer{

}

.StapperMainContainer .StepperHeader{
    background: #F7F9FA;
    padding: 0 26px;
}

.StapperMainContainer .StepperHeader .MuiPaper-root {
    background-color: transparent;
    max-width: 1000px;
    margin: 0 auto;
}

.StapperMainContainer .StepperHeader .MuiStepIcon-root.MuiStepIcon-active{
    color: #56B149;
    width: 29px;
}

.StapperMainContainer .StepperHeader .MuiStepIcon-root.MuiStepIcon-completed{
    color: #56B149;  
    font-size: 29px
}

.StapperMainContainer .StepperHeader .MuiStepLabel-label{
    font-size: 14px;
    font-weight: 500;
    font-family: 'Poppins' !important;
}


.StapperMainContainer .completed{
    display: inline-block;
}

.StapperMainContainer .FormBody {
    margin: 0 auto;
    padding: 0 30px;
    padding-bottom: 30px;
    margin-top: 32px;
    position: relative;
}


.StapperMainContainer .FormButtonBody {
    margin-top: 20px;
    border-top: #E4E8EC 1px solid;
    padding-top: 24px;
    display: flex;
    justify-content: space-between;
}

.StapperMainContainer .FormButtonBody .MuiButton-containedPrimary {
        color: #fff;
        background-color: #56B149;
        height: 50px;
        min-width: 138px;
        font-size: 14px;
        font-weight: 500;
        font-family: 'Poppins';
        border-radius: 4px;
        padding: 0 15px;
    }

.StapperMainContainer .FormButtonBody .MuiButton-root.Mui-disabled {
     color: rgba(0, 0, 0, 0.26) !important;
}


.StapperMainContainer .FormButtonBody .buttonBack {
    border: #E4E8EC 1px solid !important;
    height: 50px;
    font-size: 14px !important;
    font-weight: 500;
    font-family: 'Poppins';
    border-radius: 4px !important;
    color: #2490F9 !important;
    padding: 0 15px !important;
}


.StapperMainContainer .FormButtonBody .buttonBack .MuiSvgIcon-root{
    font-size: 16px !important;
    margin-right: 4px;    
}








